/* Media queries för mobilskärmar */
@media (max-width: 768px) {
  .section, .aboutsection, #skills {
    width: 100%;
    padding: 1rem;
  }
  #projects{
    width: 100% !important;
    margin: 0 auto;
  }
  .projekticons li, .contact-links li {
    width: 3rem;
    height: 3rem;
  }

  .direct-contact-links li {
    width: 3rem;
  }
  .direct-contact-links li a {
    width: 5rem;
    height: 5rem;
  }
  #profilbild{
    width: 12rem;
  }
  .fa-github, .fa-envelope, .fa-linkedin {
    font-size: 2rem;
  }
  .skillgrid {
    gap: 0.2rem !important; 
    width: 100% !important;
    padding: 0;
    margin: 0 auto;
  }
  .projectphoto img {
    width: auto;
    height: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  

}