
/* PROJECTS-SIDAN OCH SKILLS-SIDAN */

#projects.projectsection { 
    padding-top: 4rem;
    text-align: center;
    font-size: 1.2rem;
    width: 70%;
    box-sizing: border-box;
    margin: 0 auto;
  }
  
  #skills { 
    padding-top: 4rem;
    text-align: center;
    color: #00204a;
  }
  #skills h3 {
    color: white;
  }
  .skillgrid {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    width: 55%;
  }
  .liskills {
    background-color: hsla(0, 0%, 100%, 0.5);
    box-shadow: 0.1rem 0.1rem 0.2rem rgba(255, 255, 255, 0.4);
    border-radius: 1rem;
    padding: 0.6rem 0.6rem;
    margin: 0.15rem;
    list-style: none;
    text-align: center;
    transition: all 0.3s ease; 
  }
  .liskills:hover {
      transform: scale(1.08); 
      background-color: hsla(0, 0%, 100%); 
      box-shadow: 0.1rem 0.1rem 0.2rem rgba(255, 255, 255, 0.8);
    }
  .goldenskill{
    background-color: hsla(0, 0%, 100%, 0.95);
  
  }
  #projects { 
    padding-top: 4rem;
    text-align: center;
    font-size: 1.2rem;
    width: 100%;
    box-sizing: border-box;
  }
  .projectgrid {
    color: black;
    margin-top: 1rem;
    display: grid; 
    grid-template-columns: 1fr; 
    gap: 1rem;
    width: 100%;
    justify-items: center; 
    padding: 0;
  }
  
  .li-projects {
    background-color: hsla(0, 0%, 100%, .1);
    box-shadow: 0.1rem 0.6rem 0.9rem rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    padding: 1rem;
    list-style: none;
    text-align: left;
    transition: transform 0.3s ease; 
    width: 80%; 
  }
  .projectphoto img {
    max-width: 100%;
    height: 25rem;
    display: block;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .image-wrapper {
    padding: 1rem 0;
  }
  
  .placeholder-image {
    width: 100%;
    height:15rem;
    margin: 0 auto;
    background-color: hsla(0, 0%, 100%, .5);
    filter: blur(2px);
    border-radius: 5px;
  }
  
  
  .li-projects:hover {
    transform: scale(1.02); 
  }
  .li-projects a{
    display: block;  
    color: white;
    font-size: 1.5rem;  
    text-align: center !important;
  }
  .tags {
    text-align: left;
    font-size: 0.8rem; 
    font-weight: bold;
    letter-spacing:0.025rem;
    margin-top: -1rem;
  color: white;
    
  }
  .tags span {
    display: inline-block;
  }