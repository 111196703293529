*{
  box-sizing: border-box !important;  

}


.App {
  text-align: center;
  color: black;

}
h2{
  margin-bottom: 0rem;
  color:white;
}
a {
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
} 
p{
  color: white;
}
body{
  background: linear-gradient(100deg, rgb(76, 221, 242) 11.2%, rgb(92, 121, 255) 50%);
  background-attachment: fixed; 
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #00204a;
  z-index: 1000;
}
.header nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}
.header nav ul li {
  margin: 0 0.8rem;
}
.header nav ul li a {
  text-decoration: none;
  color: white; 
  font-weight: bold;
  transition: color 0.3s ease;
}
.header nav ul li a:hover,
.header nav ul li a.active {
  color:  #00bbf0; 
}

/* sections nedan */

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 100%; 
  box-sizing: border-box; 
}
.aboutsection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  width: 50%; 
  margin: 0 auto; 
  box-sizing: border-box;
}

#home { 
  padding-top: 3rem;
  font-size: 1.2rem;
  text-align: left;
  margin-left: 1rem;
  margin-right: 1rem;
}
#hometexten{
  padding-bottom: 1rem;
  text-align: center;
}
#profilbild{
  margin-top: 1rem;
  width: 15rem;
  object-fit: cover;
  border-radius: 9rem;  
}
.projekticons {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.projekticons li {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size:3.5rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}
.projekticons li img {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 3rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  transition: background-color 0.3s ease;
}
.contact-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.contact-links li {
  margin: 0 0.5rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.3s ease;
}
.fa-github,
.fa-envelope,
.fa-linkedin {
  font-size: 2rem;
  color: #00204a;
}

.direct-contact-links {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.direct-contact-links li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.5rem;
  margin-top: 10%;
}

.direct-contact-links li p {
  margin-bottom: 1rem;
  color:white;
}

.direct-contact-links li a {
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color:white;
  transition: background-color 0.3s ease;
}

.direct-contact-links .fa-github,
.direct-contact-links .fa-envelope,
.direct-contact-links .fa-linkedin {
  font-size: 3rem;
  color: #00204a;
}

#about { 
  padding-top: 4rem;
  text-align: left;
  color:black ;
  font-size: 1.2rem;
  line-height: 1.5;
}
.fa-bug {
  color: #00204a;
}

#contact { 
  padding-top: 0rem;
  text-align: center;
  font-size: 1.2rem;
}
.contact-special {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.contact-special li {
  margin: 0 0.5rem;
  width: 4rem;
  display: flex;
  justify-content: center;
}
